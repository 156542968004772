<template>
  <div>
    <a-modal
      :visible="visible"
      title="负载类型选择"
      width="55%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-select
        style="width: 100%"
        v-model="selectedRowKeys"
        allowClear
        mode="multiple"
        showSearch
        :showArrow="false"
        v-if="tableDate.length && selectType == 'checkbox'"
        optionFilterProp="label"
        :open="false"
      >
        <a-select-option
          v-for="loadedDevice in loadedDeviceTypes"
          :label="loadedDevice.name"
          :key="loadedDevice.loadedDeviceTypeId"
          >{{ loadedDevice.name }}</a-select-option
        >
      </a-select>
      <div class="product-operations">
        <div></div>
        <div class="data-filter">
          <a-input
            placeholder="请输入负载类型名称搜索"
            v-model="searchKeyword"
            class="search-keyword"
            clearable
            @keyup.enter.native="listLoadedDevices"
          ></a-input>
          <a-button class="search-btn" type="primary" @click="listLoadedDevices()">搜索</a-button>
        </div>
      </div>
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: selectType }"
        size="middle"
        :pagination="true"
        :columns="this.$constRepository.loadedDevice.columns"
        :data-source="tableDate"
        class="loaded-device-table"
        rowKey="loadedDeviceTypeId"
      >
        <template slot="createdTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
        <template slot="lastModificationTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
        <template slot="regions" slot-scope="scope">
          <span v-if="scope">
            {{ scope.map((region) => region.name).join('、') }}
          </span>
          <span v-else>-</span>
        </template>
        <template slot="action" slot-scope="scope">
          <a-popover trigger="click" title="属性字段" placement="left">
            <template #content>
              <div style="max-height: 400px; overflow-y: auto">
                <a-descriptions bordered>
                  <a-descriptions-item label="负载类型ID">{{ scope.loadedDeviceTypeId }}</a-descriptions-item>
                  <a-descriptions-item label="负载类型名称">{{ scope.name }}</a-descriptions-item>
                  <a-descriptions-item label="负载类型类别">
                    <span v-if="scope.categories">
                      {{
                        scope.categories
                          .map((category) => $constRepository.loadedDevice.CategoriesLabel[category])
                          .join('、')
                      }}
                    </span>
                    <span v-else> - </span>
                  </a-descriptions-item>
                </a-descriptions>
                <template v-if="scope.properties && scope.properties.length">
                  <h3 style="margin-top: 1rem">属性</h3>
                  <loaded-device-property-card :properties="scope.properties" />
                </template>
              </div>
            </template>
            <a-button type="link">查看</a-button>
          </a-popover>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import lodash from 'lodash';
import LoadedDevicePropertyCard from './loadedDevicePropertyCard';

export default {
  name: 'PropertyFormModal',
  components: { LoadedDevicePropertyCard },
  data() {
    return {
      visible: false,
      selectType: 'checkbox',
      resultCallBack: {
        onSubmit: undefined,
        doSearch: undefined,
      },
      searchKeyword: '',
      selectedRowKeys: [],
      tableDate: [],
      loadedDeviceTypes: [],
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    async listLoadedDevices() {
      if (this.resultCallBack.doSearch !== undefined) {
        this.tableDate = await this.resultCallBack.doSearch(this.searchKeyword);
        return;
      }
      const params = { name: this.searchKeyword, unpaged: true };
      const response = await this.$apiManager.loadedDeviceType.getLoadedDeviceTypes(params);
      this.tableDate = response.data.elements;
      this.loadedDeviceTypes = !this.loadedDeviceTypes.length ? response.data.elements : this.loadedDeviceTypes;
    },
    hideDialog() {
      this.visible = false;
      this.selectedRowKeys = [];
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onChange() {},
    onShowSizeChange() {},
    async showSelectDialog(callback, selectType = 'checkbox', loadedList = [], doSearch = undefined) {
      this.selectType = selectType;
      if (loadedList.length) {
        this.tableDate = loadedList;
      } else {
        await this.listLoadedDevices();
      }
      // 将已选择置于表格前;
      const target = [];
      this.tableDate = this.tableDate.filter((item) => {
        if (!this.selectedRowKeys.includes(item.loadedDeviceTypeId)) {
          return true;
        }
        target.push(item);
        return false;
      });
      this.tableDate = [...target, ...this.tableDate];
      this.resultCallBack.onSubmit = callback;
      if (doSearch) {
        this.resultCallBack.doSearch = doSearch;
      }
      this.visible = true;
    },
    showUpdateDialog(pararms, callback) {
      this.listLoadedDevices();
      this.productCategoryForm = pararms;
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    onSubmit() {
      const keys = this.selectedRowKeys.map((key) => ({ loadedDeviceTypeId: key }));
      let params;
      if (this.selectType === 'checkbox') {
        params = lodash.intersectionBy(this.loadedDeviceTypes, keys, 'loadedDeviceTypeId');
      } else {
        params = lodash.intersectionBy(this.tableDate, keys, 'loadedDeviceTypeId');
      }
      this.resultCallBack.onSubmit(this.selectedRowKeys, params);
      this.hideDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.popover-content {
  width: 80vw;
}
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.product-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .product-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
</style>
