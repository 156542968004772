<template>
  <div>
    <div style="text-align: right; margin-bottom: 0.5rem">
      <a-popconfirm title="确认清空所有推荐负载类型?" @confirm="clearAllRecommendLoadedDevice">
        <a-button type="primary">一键清空</a-button>
      </a-popconfirm>
    </div>
    <a-table :columns="columns" :data-source="listData" row-key="productId">
      <template slot="productName" slot-scope="scope">
        {{ scope.productName }}
      </template>
      <template slot="protocol" slot-scope="scope">
        {{ productProtocol(scope.protocol) }}
      </template>
      <template slot="type" slot-scope="scope">
        {{ productType(scope.type) }}
      </template>
      <template slot="loadedDeviceType" slot-scope="scope">
        <span v-if="scope.loadedDeviceTypeId">
          <a-tag color="#2db7f5">{{
            `${scope.loadedDeviceType.loadedDeviceTypeName}(id:${scope.loadedDeviceType.loadedDeviceTypeId})`
          }}</a-tag>
        </span>
        <span v-else>-</span>
      </template>
      <template slot="operation" slot-scope="scope">
        <a-button type="link" @click="handleSelectRecommendLoadedDevice(scope)"> 编辑 </a-button>
        <a-popconfirm
          :title="`确认清空${scope.product.productName}推荐负载类型?`"
          @confirm="clearRecommendLoadedDevice(scope)"
        >
          <a-button type="link" style="color: red"> 清空 </a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <LoadedDeviceSelectModal ref="loadedDeviceSelectModal" />
  </div>
</template>

<script>
import LoadedDeviceSelectModal from '../../loaded-device/components/LoadedDeviceSelectModal';

const columns = [
  {
    dataIndex: 'productId',
    key: 'productId',
    title: '产品ID',
    align: 'center',
  },
  {
    dataIndex: 'product',
    key: 'productName',
    title: '产品名称',
    align: 'center',
    scopedSlots: { customRender: 'productName' },
  },
  {
    dataIndex: 'product',
    key: 'protocol',
    title: '产品控制协议类型',
    align: 'center',
    scopedSlots: { customRender: 'protocol' },
  },
  {
    dataIndex: 'product',
    key: 'type',
    title: '产品类型',
    align: 'center',
    scopedSlots: { customRender: 'type' },
  },
  {
    key: 'loadedDeviceType',
    title: '推荐负载类型',
    align: 'center',
    scopedSlots: { customRender: 'loadedDeviceType' },
  },
  {
    key: 'operation',
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'operation' },
  },
];
export default {
  name: 'ProductSubClassRecommendLoadedDeviceTypeTable',
  components: { LoadedDeviceSelectModal },
  data() {
    return {
      columns,
      metaData: {
        productTypes: [],
        productProtocols: [],
      },
    };
  },
  props: ['data', 'propsMetaData'],
  computed: {
    listData: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit('updateData', value);
      },
    },
    productType() {
      return (value) => {
        if (this.metaData.productTypes) {
          const typeName = this.metaData.productTypes.find((md) => md.value === value);
          return typeName ? typeName.name : '';
        }
        return '';
      };
    },
    productProtocol() {
      return (protocol) => {
        if (this.metaData.productProtocols) {
          const protocolsName = this.metaData.productProtocols?.find((md) => md.value === protocol);
          return protocolsName ? protocolsName.name : '';
        }
        return '';
      };
    },
  },
  methods: {
    clearAllRecommendLoadedDevice() {
      this.listData.forEach((product) => this.clearRecommendLoadedDevice(product));
    },
    async handleSelectRecommendLoadedDevice(product) {
      const loadedDeviceTypeIds = product.product.loadedDeviceTypeIds || [];
      let loadedDeviceTypeList;
      if (loadedDeviceTypeIds.length) {
        // 根据ids 获取负载列表
        const params = {
          loadedDeviceTypeId: loadedDeviceTypeIds.join(','),
          unpaged: true,
        };
        const loadedDeviceResponse = await this.$apiManager.loadedDeviceType.getLoadedDeviceTypes(params);
        loadedDeviceTypeList = loadedDeviceResponse.data.elements || [];
      } else {
        loadedDeviceTypeList = [];
      }
      // 打开负载选择框
      this.$refs.loadedDeviceSelectModal.showSelectDialog(
        (loadedDeviceTypeId, loadedDeviceType) => {
          if (loadedDeviceTypeId[0]) {
            this.$set(product, 'loadedDeviceTypeId', loadedDeviceTypeId[0]);
          }
          if (loadedDeviceType[0]) {
            this.$set(product, 'loadedDeviceType', {
              loadedDeviceTypeId: loadedDeviceType[0].loadedDeviceTypeId,
              loadedDeviceTypeName: loadedDeviceType[0].name,
            });
          }
        },
        'radio',
        loadedDeviceTypeList,
        async (name) => {
          if (loadedDeviceTypeIds.length) {
            const loadedDeviceResponse = await this.$apiManager.loadedDeviceType.getLoadedDeviceTypes({
              loadedDeviceTypeId: loadedDeviceTypeIds.join(','),
              name,
              unpaged: true,
            });
            return loadedDeviceResponse.data.elements;
          }
          return [];
        },
      );
    },
    clearRecommendLoadedDevice(product) {
      this.$set(product, 'loadedDeviceTypeId', undefined);
      this.$set(product, 'loadedDeviceType', undefined);
    },
  },
  watch: {
    propsMetaData: {
      handler(newValue) {
        this.metaData = newValue;
      },
      immediate: true,
    },
  },
};
</script>
