<template>
  <div>
    <a-modal
      :visible="visible"
      title="产品大类选择"
      width="55%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <div class="data-filter">
        <div></div>
        <div>
          <a-input-search
            v-model="requestParams.name"
            class="search-keyword"
            clearable
            placeholder="请输入产品大类名称"
            @keyup.enter.native="doSearch"
            @search="doSearch"
          ></a-input-search>
        </div>
      </div>
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: selectType }"
        size="middle"
        :pagination="false"
        :columns="columns"
        :data-source="tableDate"
        rowKey="id"
      >
        <template slot="createdTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
        <template slot="lastModificationTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pageInfo.page"
          :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
          show-quick-jumper
          show-size-changer
          :total="pageInfo.totalElements"
          @showSizeChange="onShowSizeChange"
          @change="onChange"
          :pageSizeOptions="['10', '20', '30', '40']"
        />
        <br />
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'PropertyFormModal',
  components: {},
  mounted() {
    this.listProductClasses();
  },
  data() {
    return {
      visible: false,
      selectType: 'radio',
      resultCallBack: {
        onSubmit: Function,
      },
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      requestParams: {},
      selectedRowKeys: [],
      tableDate: [],
      columns: [
        {
          title: '产品大类名称',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
        },
        {
          title: '所属产品目录名称',
          dataIndex: 'productCategoryName',
          key: 'productCategoryName',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
          scopedSlots: { customRender: 'createdTime' },
          align: 'center',
        },
        {
          title: '上次修改时间',
          dataIndex: 'lastModificationTime',
          key: 'lastModificationTime',
          scopedSlots: { customRender: 'lastModificationTime' },
          align: 'center',
        },
      ],
    };
  },
  methods: {
    async listProductClasses() {
      const params = {
        name: this.requestParams.name,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.productClasses.getProductClasses(params);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.listProductClasses();
    },
    onShowSizeChange() {},
    showDialog(callback) {
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    showUpdateDialog(pararms, callback) {
      this.form = pararms;
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    onSubmit() {
      if (this.selectedRowKeys[0]) {
        const params = this.tableDate.find((select) => select.id === this.selectedRowKeys[0]);
        this.resultCallBack.onSubmit(params);
        this.hideDialog();
      } else {
        this.$message.warn('请选择产品大类');
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.listProductClasses();
    },
  },
};
</script>

<style lang="less" scoped>
.data-filter {
  display: flex;
  justify-content: space-between;
  .tip {
    color: #4c4c4c;
  }
  .date-picker-style {
    margin: 0 10px;
  }
  .search-options {
    width: 100px;
  }
  .search-keyword {
    width: 200px;
    margin-left: 2%;
  }
  .search-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
}
</style>
